//main.js
import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'

Vue.config.productionTip = false

//i18n
import i18n from './i18n'

//vuetify
import vuetify from './plugins/vuetify';

//shared-ui
import syncoboxSharedUi from "@syncobox/syncobox-shared-ui";
import "@syncobox/syncobox-shared-ui/dist/syncobox-shared-ui.css";
Vue.use(syncoboxSharedUi, { store: store });


//shared-framework
import './plugins/sharedFramework';


// kendo
import "./plugins/kendo"

// syncobox-fomio
import syncoboxFormio from "@syncobox/syncobox-formio"
import "@syncobox/syncobox-formio/dist/syncobox-formio.css"
Vue.use(syncoboxFormio, { store, i18n });

// forms
import syncoboxForm from "@syncobox/syncobox-form/dist/syncobox-form.common";
import "@syncobox/syncobox-form/dist/syncobox-form.css"
Vue.use(syncoboxForm, { store: store, router: router });

// BIM
import syncoboxBIM from '@syncobox/syncobox-bim'

let authSetting = {
    authority: `https://developer.api.autodesk.com`,
    clientId: process.env.VUE_APP_FORGE_CLIENT_ID,
    redirectUri: process.env.VUE_APP_FORGE_CALLBACK_URL,
    responseType: "token",
    scope: [`data:read`]
}
Vue.use(syncoboxBIM, { store: store }, authSetting)


import API from '@syncobox/syncobox-shared-api'
API.config.mainBaseUrl= 'https://api-dev.syncobox.com';
API.config.bimBaseUrl='https://webim-api-dev.syncobox.com';
API.config.formBaseUrl= 'https://form-api-dev.syncobox.com';
API.config.docBaseUrl= 'https://doc-api-dev.syncobox.com';
API.config.pcBaseUrl='https://pc-api-dev.syncobox.com';
API.config.flowBaseUrl= 'https://flow-api-dev.syncobox.com';
API.config.operationBaseUrl= 'https://operation-api-dev.syncobox.com';
API.config.reportingBaseUrl= 'https://reporting-api-dev.syncobox.com';
API.config.responseErrorHandler = async (error) => {
  if (API.config.doesUseDefaultErrorHandler) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      if (error.response) {

          switch (error.response.status) {
              case 400:
                  // console.log("Bad Request")
                  // go to 400 page
                  // eslint-disable-next-line no-case-declarations
                  const message = error && error.response ? error.response.data : JSON.stringify(error);
                  await store.dispatch('notification/warn', `${message}`)
                  break
              case 401:
                  // console.log("你沒有權限")
                  // go to 401 page
                  // if (options.oidc && options.oidc !== false) {
                  //     try {
                  //         await  store.dispatch('oidcStore/authenticateOidcSilent')
                  //     } catch (err) {
                  //         console.error(err)
                          await  store.dispatch('oidcStore/authenticateOidc')
                  //     }
                  // }
                  break
              case 403:
                  // go to 403 page
                  // console.warn("你未有足夠存取該資源的權限", error.config.baseURL)
                  //error.response.status
                  await store.dispatch('notification/warn', `${JSON.stringify(error)}`)
                  break
              case 404:
                  // console.log("你要找的頁面不存在")
                  await store.dispatch('notification/warn', `${JSON.stringify(error)}`)
                  // go to 404 page
                  break
              case 500:
                  // console.log("程式發生問題")
                  await store.dispatch('notification/error', `${JSON.stringify(error)}`)
                  // go to 500 page
                  break
              default:
                  // console.log(error.message)
                  if (error.response.status >= 400 && error.response.status < 500) {
                      await store.dispatch('notification/warn', JSON.stringify(error))
                  } else if (error.response.status >= 500 && error.response.status < 600) {
                      await store.dispatch('notification/error', JSON.stringify(error))
                  } else {
                      console.error(error.message)
                  }
          }
      }
      if (!window.navigator.onLine) {
          alert("網路出了點問題，請重新連線後重整網頁");
          await store.dispatch('notification/error', error)
          return;
      }
  }
  return Promise.reject(error);
}


Vue.prototype.$API = API


//pages
import './plugins/appPage';

//dynamically add routes 
//為了避免path直接mapping進入到 CompanyApps
//所以把動態的部分最後加入routes
import MainLayout from '@/layouts/Main.vue'
router.addRoutes([{
    path: '',
    name: "Main",
    component: MainLayout,
    children: [
        {
            path: '/:companyRoute',
            name: 'AppList',
            component: () =>
                import ("@/views/AppList.vue"),
        },
        {
            path: '/:companyRoute/:appRoute',
            name: 'PageList',
            component: () =>
                import ("@/views/PageList.vue"),
            beforeEnter: (to, from, next) => {
                next()
            }
        },
        {
            path: '/:companyRoute/:appRoute/page/:pageId',
            name: 'PageDynamic',
            component: () =>
                import ("@/views/Page.vue"),
        }
    ]
}])



new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: h => h(App)
}).$mount('#app')