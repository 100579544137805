import ListToTree from "@/utils/ListToTree";
import { mapState } from "vuex"

export default {
    computed: {
        companyRoute() {
            return this.$route.params.companyRoute || this.$store.state.auth.userInfo.employedBy.route;
        },
        appRoute() {
            return this.$route.params.appRoute;
        },
        API() {
            return this.$API.api.main
        },
        ...mapState('company',{company:"company"}),
        ...mapState("SyncoBoxAppPageStoreModule", {appId:"appId"})
    },
    methods: {

        async getAppId() {
            if (this.appRoute && this.companyRoute) {
                try {
                    await this.API.companyApp
                        .getMetaDataByRoutes(this.companyRoute, this.appRoute)
                        .then((res) => res.data)
                        .then((app) => {
                            this.$store.commit(
                                "SyncoBoxAppPageStoreModule/SET_APP_ID",
                                app.id
                            );
                        });
                } catch (err) {
                    console.error(`ERROR: request to get getAppId error,  ${err}`);
                }
            } else {
                console.error(`ERROR:not provide appRoute or companyRoute`);
            }
        },


        async getMenuItems() {
            this.flatMenuItems = [];
            if (this.appRoute && this.companyRoute) {
                this.isSidebarMenuLoading = true;
                try {
                    this.flatMenuItems = await this.API.companyApp
                        .getMenuItemsByRoutes(this.companyRoute, this.appRoute)
                        .then((res) => res.data)
                        .then((menuItems) =>
                            menuItems.map((item) => {
                                console.log('this.appId', this.appId)
                                return {
                                    ...item,
                                    name: "Page",
                                    params: {
                                        pageId: item.pageId,
                                        companyId: this.company.id,
                                        appId: this.appId,
                                    },
                                };
                            })
                        );
                } catch (err) {
                    console.error(`ERROR: request to get menuItems error,  ${err}`);
                } finally {
                    this.isSidebarMenuLoading = false;
                }
                
            } else {
                this.isSidebarMenuLoading = true;
                console.error(`ERROR:not provide appRoute or companyRoute`);
                this.isSidebarMenuLoading = false;
            }
        },


        async getCompanyIdByRoute() {
            if(this.companyRoute){
                try {
                    let companyId = await this.API.company
                        .getIdByRoute(this.companyRoute)
                        .then((res) => res.data);
                    this.getCompanyById(companyId)
                    //this.$route.params.companyId = companyId
                } catch (err) {
                    console.error(`ERROR: request to get companyId error,  ${err}`);
                }
            }
        },
    },
    watch: {
        "$route.params.companyRoute": {
            immediate: true,
            async handler() {
                await this.getCompanyIdByRoute();
            }
        },
        "$route.params.appRoute": {
            immediate: true,
            async handler() {
                await this.getAppId();
                this.getMenuItems();
            }
        },
        flatMenuItems(flatMenuItems){
            this.menuItems = ListToTree(flatMenuItems, "parentId", "subMenuItems");
        }
    }

}