//router/index.js
import Vue from 'vue'
import VueRouter from 'vue-router'
import MainLayout from '@/layouts/Main.vue'
import store from "@/store";
import { vuexOidcCreateRouterMiddleware } from 'vuex-oidc'

Vue.use(VueRouter)

const routes = [{
        path: '',
        name: "Main",
        component: MainLayout,
        children:[{
            path: '/',
            name: 'CompanyList',
            component: () =>
            import ("@/views/CompanyList.vue"),
        },
        {
            //必須要有'Page'當作錨定點才可以定義各對應的params再做導向,
            //否則會全部當作相同的路由僅param不同
            path: '/:companyRoute/:appRoute/page/:pageId?',
            name: 'Page',
            redirect(to){
                if(to.params.pageId==undefined){
                    return {name:"PageList", params:to.params}
                }else{
                    return {name:"PageDynamic", params:to.params}
                }   
            }
        }
    ]
    }]

let router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach(vuexOidcCreateRouterMiddleware(store, "oidcStore"));

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = "none";
    }
})

export default router