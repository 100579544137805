<template>
  <sb-main
    :navMenuItems="menuItems"
    :navbarVerticalItems="navbarVerticalItems"
    :iconUrl="iconUrl"
    :textUrl="txtUrl"
    :user="user"
    :isRenderingVApp="isRenderingVApp"
    :isFooterVisible="isFooterVisible"
    isI18n
    :is-sidebar-menu-loading='isSidebarMenuLoading'
    :logo-link="logoLink"
  >
    <template #header_prepend>
      <v-select
        v-model="selectedCompany"
        v-show="isCompanySeletVisible"
        :items="userInfo?userInfo.companies:[]"
        outlined
        hide-details
        return-object
        :item-text="'name'"
        :item-value="'id'"
        dense
        class="mr-2"
        :style="{maxWidth:'240px'}"
      ></v-select>
    </template>
    <router-view ></router-view>
    <template #footer_version>
      <v-divider vertical class="mx-2 my-2"></v-divider>
      <v-btn
        class="cpation pa-0 ml-2 mr-4"
        text
        :ripple="false"
        target="_blank"
        :href="infoUrl+'/Terms.html'"
        color="primary"
      >{{$t('terms_of_service')}}</v-btn>
      <v-btn
        class="cpation pa-0 mr-2"
        text
        :ripple="false"
        target="_blank"
        :href="infoUrl+'/Private.html'"
        color="primary"
      >{{$t('privacy_policy')}}</v-btn>
      <v-divider vertical class="mx-2 my-2"></v-divider>
      <span class="caption ml-2">
        {{
        $t("application_version", { version: version })
        }}
      </span>
    </template>
  </sb-main>
</template>

<script>
import { mapState, mapActions } from "vuex";
import navAccountMenuItems from "./navAccountMenuItems";
import sbAppMixin from '../mixins/sbAppMixins'
import packageJson from "@/../package.json"

export default {
  name: "mainLayout",
  data() {
    return {
      menuItems:[],
      flatMenuItems:[],
      iconUrl: "/logo-icon.png",
      txtUrl: "/logo-text.png",
      navbarVerticalItems: navAccountMenuItems,
      infoUrl: process.env.VUE_APP_INFO_BASE_URL,
      version: packageJson.version,
      isCompanySeletVisible: false,
      isRenderingVApp:false,
      isSidebarMenuLoading:false,
      logoLink:process.env.VUE_APP_REDIRECT_BASE_URL
    };
  },
  mixins:[sbAppMixin],
  computed: {
    ...mapState("auth", ["userInfo"]),
    ...mapState("company", {
      currentCompany:state=>state.company,
      currentCompanyModules:state=>state.modules
      }),
    ...mapState("project", {
      currentProject:state=>state.project,
      currentProjectModules:state=>state.modules
      }),
    ...mapState("layout", ['isFooterVisible']),
    systemRoles(){
      return this.userInfo && this.userInfo.systemRole ? this.userInfo.systemRole :[]
    },
    userCompanyRoles(){
        return this.$store.state.auth.company.roles
    },
    userProjectRoles(){
        return this.$store.state.auth.project.roles
    },
    user() {
      return {
        email: this.userInfo ? this.userInfo.email : "",
        name: this.userInfo ? this.userInfo.name : "",
      };
    },
    selectedCompany: {
      get() {
        return this.currentCompany;
      },
      async set(selectedCompany) {
        let companyId = selectedCompany? selectedCompany.id: undefined;
        let companyName = selectedCompany? selectedCompany.name: undefined;
        this.setMenuItemsLoad(this.menuItems, true, "companyId");
        await this.getCompanyById(companyId);
        if(this.$route.meta.type==='company'){
          let cloneParams = Object.assign(this.$route.params, {companyId})
          let cloneQuery = Object.assign(this.$route.query, {companyName})
          this.$router.push( {params: cloneParams, query:cloneQuery}).catch(() => {});
        }
        this.setMenuItemsLoad(this.menuItems, false, "companyId");
      },
    },
  },
  methods: {
    ...mapActions("auth", ["getProjectRoles","getProjectFunctions","getCompanyRoles","getCompanyFunctions",]),
    ...mapActions("project", ["getProjectById"]),
    ...mapActions("company", ["getCompanyById"]),
    toggleMenuItems(menuItems = []) {
      for (let i = 0; i < menuItems.length; i++) {
        //init
        let cloneItem = JSON.parse(JSON.stringify(menuItems[i]));
        cloneItem.params = cloneItem.params ? cloneItem.params : {};
        cloneItem.query = cloneItem.query ? cloneItem.query :{};
        cloneItem.meta = cloneItem.meta? cloneItem.meta:{};
        cloneItem.meta.modules = cloneItem.meta.modules? cloneItem.meta.modules:[];
        cloneItem.meta.roles = cloneItem.meta.roles? cloneItem.meta.roles:[];
        cloneItem.subMenuItems = cloneItem.subMenuItems? cloneItem.subMenuItems: [];

        //project related properties setting
        cloneItem.projectName = this.currentProject ? this.currentProject.name : null;
        cloneItem.params.projectId = this.currentProject ? this.currentProject.id : null;

        //company related properties setting
        cloneItem.companyName = this.currentCompany ? this.currentCompany.name : null;
        cloneItem.params.companyId = this.currentCompany ? this.currentCompany.id : null;

        if(cloneItem.meta.type==="company"){
          cloneItem.query.companyName = cloneItem.companyName;
        }

        //setVisibilityByPermission
        //cloneItem = this.setVisibilityByPermission(cloneItem);
        this.$set(menuItems, [i], cloneItem);

        //recursice calling itself if it has subMenuItems
        if (menuItems[i].subMenuItems && menuItems[i].subMenuItems.length > 0) {
          this.toggleMenuItems(menuItems[i].subMenuItems);
        }
      }
    },
    setMenuItemsLoad(menuItems = [], bool, param) {
      for (let i = 0; i < menuItems.length; i++) {
        let cloneItem = JSON.parse(JSON.stringify(this.menuItems[i]));

        let routeOfMenuItem = cloneItem.name
          ? this.$router.resolve({
              name: cloneItem.name,
            }).route
          : undefined;

        const isParamRequired = routeOfMenuItem
          ? routeOfMenuItem.matched.filter((i) => i.path.includes(":" + param))
              .length > 0
          : undefined;
        const isLoading = isParamRequired ? bool : false;

        this.$set(menuItems, [i], {
          ...cloneItem,
          loading: isLoading,
        });
      }
    },
    setVisibilityByPermission(cloneItem){
        if(this.userInfo)
        switch (cloneItem.meta.type) {
          case "system" :{
            let isMatchedRole = this.systemRoles.some((roleItem) =>  cloneItem.meta.roles.includes(roleItem.name) ) ;
            cloneItem.visible  = isMatchedRole;
            break;
          }
          case "company" :{
            let isMatchedRole = this.userCompanyRoles.some((roleItem) =>  cloneItem.meta.roles.includes(roleItem.name) ) ;
            let isMatchedModule = this.currentCompanyModules?this.currentCompanyModules.some(module=> cloneItem.meta.modules.includes(module.value)):false ;
            cloneItem.visible  =  isMatchedRole && isMatchedModule;
            break;
          }
          case "project" :{
            let isMatchedRole = this.userProjectRoles.some((roleItem) =>  cloneItem.meta.roles.includes(roleItem.name) ) ;
            let isMatchedModule = this.currentProjectModules.length>0 ?this.currentProjectModules.some(module=> cloneItem.meta.modules.includes(module.value)):false ;
            cloneItem.visible  =  isMatchedRole && isMatchedModule;
            break;
          }
          default:{
            cloneItem = this.setVisibilityByRoute(cloneItem)
            break;
          }
        }
      return cloneItem
    },
    setVisibilityByRoute(cloneItem){
       //defined by routes
        let routeOfMenuItem = this.$router.resolve({
          name: cloneItem.name,
        }).route;

        //project
        const isProjectIdRequired =
          routeOfMenuItem.matched.filter((i) => i.path.includes(":projectId"))
            .length > 0 || cloneItem.meta.type==='project';
        if (isProjectIdRequired) {
          cloneItem.visible = this.currentProject ? true : false;
        }

        //company
        const isCompanyIdRequired =
          routeOfMenuItem.matched.filter((i) => i.path.includes(":companyId"))
            .length > 0 || cloneItem.meta.type==='company';
        if (isCompanyIdRequired) {
          cloneItem.visible = this.company ? true : false;
          cloneItem.query = cloneItem.query ? cloneItem.query : {};
          cloneItem.query = this.company
            ? { ...cloneItem.query, companyName: this.company.name }
            : cloneItem.query;
        }
        return cloneItem
    }
    
  },
  watch: {
    currentProject: {
      handler(currentProject) {
        let projectId = currentProject?currentProject.id:undefined;
        this.getProjectRoles(projectId);
        this.getProjectFunctions(projectId);
      },
      immediate: true,
    },
    selectedCompany: {
      handler(selectedCompany) {
        let companyId = selectedCompany? selectedCompany.id: undefined;
        this.getCompanyRoles(companyId);
        this.getCompanyFunctions(companyId);
      },
      immediate: false,
    },
    userInfo: {
      handler(userInfo) {
        if (userInfo) {
          switch (userInfo.companies.length) {
            case 0: {
              this.selectedCompany = undefined;
              break;
            }
            case 1: {
              this.selectedCompany = userInfo.employedBy;
              break;
            }
            default: {
              this.selectedCompany = userInfo.employedBy;
              this.isCompanySeletVisible = true;
              break;
            }
          }
        }
      },
      immediate: true,
    },
    userCompanyRoles: {
      handler() {
        //this.toggleMenuItems(this.menuItems);
      },
      immediate: false,
    },
    userProjectRoles: {
      handler() {
        //this.toggleMenuItems(this.menuItems);
      },
      immediate: false,
    },
    currentCompanyModules:{
      handler(){
        //this.toggleMenuItems(this.menuItems)
      }
    },
    currentProjectModules:{
      handler(){
        //this.toggleMenuItems(this.menuItems)
      }
    },
    $route: {
      immediate: true,
      handler: async function () {
        // check whether current route is required projectId or company,
        // if it's yes, set state

        // Project
        let isProjectIdRequired =
          this.$route.matched.filter((i) => i.path.includes(":projectId"))
            .length !== 0;
        if (!this.$store.state.project.project && isProjectIdRequired) {
          this.setMenuItemsLoad(this.menuItems, true, "projectId");
          await this.getProjectById(this.$route.params.projectId)
          this.setMenuItemsLoad(this.menuItems, false, "projectId");
        } else if (!isProjectIdRequired) {
          this.$store.commit("project/SET_PROJECT", undefined);
          this.$store.commit("project/SET_MODULES", []);
        }

        //Company
        // let isCompanyIdRequired =
        //   this.$route.matched.filter((i) => i.path.includes(":company"))
        //     .length !== 0;
        // if (!this.$store.state.company.company && isCompanyIdRequired) {
        //   this.setMenuItemsLoad(this.menuItems, true, "companyId");
        //   await this.getCompanyById( this.$route.params.companyId );
        //   this.setMenuItemsLoad(this.menuItems, false, "companyId");
        // }
      },
    },
  },
};
</script>
