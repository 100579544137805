
import Vue from 'vue'
//kendo
import {
  Grid,
  GridToolbar,
  GridNoRecords,
  GridColumnMenuFilter
} from '@progress/kendo-vue-grid'

import {
  load,
  loadMessages,
  IntlProvider,
  LocalizationProvider
} from "@progress/kendo-vue-intl";

import likelySubtags from "cldr-data/supplemental/likelySubtags.json";
import weekData from "cldr-data/supplemental/weekData.json";
import currencyData from "cldr-data/supplemental/currencyData.json";
//zh-Hant
import numbers from "cldr-data/main/zh-Hant/numbers.json";
import timeZoneNames from "cldr-data/main/zh-Hant/timeZoneNames.json";
import calendar from "cldr-data/main/zh-Hant/ca-gregorian.json";
import currencies from "cldr-data/main/zh-Hant/currencies.json";
import dateFields from "cldr-data/main/zh-Hant/dateFields.json";

import { DatePicker } from '@progress/kendo-vue-dateinputs';

import '@progress/kendo-theme-material/dist/all.css';

  // deal with internationalization

  let cldrLang = "zh-Hant";
  const messages = {
      "zh-Hant": {
          "datepicker": {
              "toggleCalendar": "切換 日曆"
          },
          "calendar": {
              "today": "今天"
          },
          "dateinput": {
              "increment": "增加",
              "decrement": "減少"
          },
          "numerictextbox": {
              "increment": "增加",
              "decrement": "減少"
          },
          "grid": {
              "command": {
                  "add": "新增",
                  "update": "更新",
                  "cancel": "取消",
                  "discard": "捨棄"
              },
              "groupPanelEmpty": "拖拽欄位到此處",
              "noRecords": "無相關資料",
              "pagerFirstPage": "首頁",
              "pagerPreviousPage": "上一頁",
              "pagerNextPage": "下一頁",
              "pagerLastPage": "末頁",
              "pagerPage": "每頁",
              "pagerOf": "頁　共 {0} 頁",
              "pagerItems": "筆",
              "pagerInfo": "{0} - {1} 筆　共 {2} 筆數據",
              "pagerItemsPerPage": "筆每頁",
              "filterClearButton":"清除",
              "filterEqOperator": "等於",
              "filterNotEqOperator": "不等於",
              "filterIsNullOperator": "為空",
              "filterIsNotNullOperator": "不為空",
              "filterIsEmptyOperator": "無值",
              "filterIsNotEmptyOperator": "有值",
              "filterStartsWithOperator": "開頭是",
              "filterContainsOperator": "包含",
              "filterNotContainsOperator": "不包含",
              "filterEndsWithOperator": "結尾是",
              "filterGteOperator": "大於等於",
              "filterGtOperator": "大於",
              "filterLteOperator": "小於等於",
              "filterLtOperator": "小於",
              "filterIsTrue": "是",
              "filterIsFalse": "否",
              "filterBooleanAll": "(全部)",
              "filterAfterOrEqualOperator": "之後或等於",
              "filterAfterOperator": "之後",
              "filterBeforeOperator": "早於",
              "filterBeforeOrEqualOperator": "早於或等於",
              "filterFilterButton": "篩選",
              "filterAndLogic": "及",
              "filterOrLogic": "或",
              "filterSubmitButton":"篩選",
              "filterTitle": "篩選",
              "filterCheckAll":"全選",
              "filterByValue": "內容篩選",
              "filterByCondition": "條件篩選",
              "sortAscending": "升序排列",
              "sortDescending": "降序排列"
          }
      }
  }

  load(
      likelySubtags,
      weekData,
      currencyData,
      numbers,
      currencies,
      calendar,
      dateFields,
      timeZoneNames
  );

  loadMessages(messages[cldrLang], cldrLang);



  // register kendo components
  Vue.component('localization-provider', LocalizationProvider)
  Vue.component('intl-provider', IntlProvider)
  Vue.component('Grid', Grid)
  Vue.component('grid-toolbar', GridToolbar)
  Vue.component('grid-no-records', GridNoRecords)
  Vue.component('grid-column-menu-filter', GridColumnMenuFilter)
  Vue.component('datepicker', DatePicker);
