import Vue from 'vue'
import store from "@/store";
import router from "@/router";
import shared from '@syncobox/syncobox-shared';

import "@syncobox/syncobox-shared/dist/syncobox-shared.css";

const apiConfig = {
    identityBaseUrl: process.env.VUE_APP_IDENTITY_BASE_URL,
    redirectBaseUrl: process.env.VUE_APP_REDIRECT_BASE_URL,
    mainBaseUrl: process.env.VUE_APP_MAIN_BASE_URL,
    bimBaseUrl: process.env.VUE_APP_BIM_BASE_URL,
    token: localStorage.getItem('oidc_access_token')
}

//auth
const oidc = {
    authority: process.env.VUE_APP_IDENTITY_BASE_URL,
    clientId: "app-spa",
    redirectUri: process.env.VUE_APP_REDIRECT_BASE_URL + "/callback",
    silentRedirectUri: process.env.VUE_APP_REDIRECT_BASE_URL + "/silent-refresh",
    post_logout_redirect_uri: process.env.VUE_APP_REDIRECT_BASE_URL + "/signout-callback",
    responseType: "code",
    scope: `openid profile offline_access pano:all webimsync:all`,
    automaticSilentRenew: true, // try to renew your token when it is about to expire
    automaticSilentSignin: false, //try to silently signin unauthenticated users on public routes. Defaults to true
    monitorSession: true,
    checkSessionInterval: 2000,
    silentRequestTimeout: 10000 //(number, default: 10000) milliseconds
}

const options = {store, router, apiConfig, oidc}
Vue.use(shared, options);